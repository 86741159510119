import { template as template_d41321117e4942d2b116e46e4921e331 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_d41321117e4942d2b116e46e4921e331(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
