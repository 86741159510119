import { template as template_ec360447fc784e168d75f1f595baafa7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_ec360447fc784e168d75f1f595baafa7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
